<template>
  <section>
    <list :type="6"></list>
  </section>
</template>

<script>
import list from '../policy/list'
export default {
  name: 'expired',
  components:{
    list
  },
}
</script>

<style scoped>

</style>
